<template>
    <section>
      <LogoLoading v-if="!wineDetail"/>
      <div v-else class="wine-detail">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-6 col-md-10 col-12 mt-5">
              <div class="d-none category wine-text">
                {{ wineDetail.type }}
              </div>
              <div class="name" v-html="processedName"></div>
              <div class="setores">
                <div class="setor">
                  <div class="wine-text-desc mb-3" v-html="wineDetail.description"/>
                  <div v-if="showYear" class="setor-year">{{ wineDetail.year }} - {{$t("yearSufix").toUpperCase() }}</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-10 col-12 image-col mt-5">
              <div class="product position-relative d-flex justify-content-center">
                <img loading=lazy class="img-fluid w-65 img-hover" :src="joinPaths(wineDetail.photoPath)" alt="" />
              </div>
            </div>
          </div>
        </div>
  
        <!--specs -->
        <div class="prizes-data">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div v-if="wineDetail.prizes.length" class="col-12 col-lg-4 prizes">
                <div class="setor-title">{{$t("premios.title").toUpperCase() }}</div>
                <div class="wine-text">
                  <div class="wine-text prize" v-for="prize in wineDetail.prizes" :key="prize.id">
                    {{ prize.year }} - {{ prize.title }}
                  </div>
                </div>
              </div>
              <div class="col-12 specs" :class=" wineDetail.prizes.length ? 'col-lg-8' : 'col-lg-12' ">
                <div class="row data-info justify-content-center">
                  <div class="col-12 col-lg data-item">
                    <img loading=lazy src="@/assets/svg/grapes.svg" class="mb-3" alt="">
                    <div class="setor-title text-center">{{$t("wines.castes")}}</div>
                    <div class="wine-text text-center text-break">{{ wineDetail.caste }}</div>
                  </div>
                  <div class="col-12 col-lg data-item">
                    <img loading=lazy src="@/assets/svg/barrel.svg" class="mb-3" alt="">
                    <div class="setor-title text-center">{{$t("wines.stage")}}</div>
                    <div class="wine-text text-center">
                      <p class="text-break">{{ wineDetail.stage }}</p>
                    </div>
                  </div>
                  <div class="col-12 col-lg data-item">
                    <img loading=lazy src="@/assets/svg/glass-wine.svg" class="mb-3" alt="">
                    <div class="setor-title text-center">{{$t("wines.consumption")}}</div>
                    <div class="wine-text text-center text-break" v-html="wineDetail.consumption"></div>
                  </div>
                  <div class="col-12 col-lg data-item">
                    <img loading=lazy src="@/assets/svg/thermometer.svg" class="mb-3" alt="">
                    <div class="setor-title text-center">{{$t("wines.temperature")}}</div>
                    <div class="wine-text text-center text-break">{{ wineDetail.temperature }}</div>
                  </div>
                  <div v-if="wineDetail.datasheet != null" class="col-12 col-lg data-item">
                    <img loading=lazy src="@/assets/svg/info.svg" class="mb-3" alt="">
                    <div class="setor-title text-center">{{$t("wines.datasheet")}}</div>
                    <div class="wine-text text-center">
                      <a
                        :href="technicalFilePath"
                        target="_blank"
                        rel="noopener"
                        >{{$t("wines.download")}}</a
                      >
                    </div>
                  </div>
                  <div v-if="wineDetail.nutritionalSheet != null" class="col-12 col-lg data-item">
                    <img loading=lazy src="@/assets/svg/info.svg" class="mb-3" alt="">
                    <div class="setor-title text-center">{{$t("wines.nutritionalSheet")}}</div>
                    <div class="wine-text text-center">
                      <a
                        :href="nutritionalFilePath"
                        target="_blank"
                        rel="noopener"
                        >{{$t("wines.check")}}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <Wines :title="titleType" :type="type" class="mt-5 mb-5" /> -->
      </div>
    </section>
  </template>
  <script>
  import router from "@/router";
  import Wines from "@/components/HomePage/wines/Wines.vue";
  import { mapState, mapActions } from 'vuex';
  import { API_HELPER } from "@/helpers/api.js";
  import LogoLoading from "@/components/LogoLoading.vue"
  import { UTILS_HELPER } from "@/helpers/utils.js";
  
  export default {
    props: {
      showYear: {
        type: Boolean
      }
    },
    metaInfo () {
      var result =
      { 
          title: this.wineDetail ? `${this.wineDetail.name} - Quinta Do Barbusano` : "Quinta Do Barbusano", 
          htmlAttrs: {
              lang: this.$i18n.locale,
          },
          meta: 
          [
              {name: 'description', content: this.wineDetail && this.wineDetail.description ? UTILS_HELPER.htmlToText(this.wineDetail.description) : this.$t("intro")},
              {name: 'keywords', content: this.wineDetail && this.wineDetail.name && this.wineDetail.type ? this.wineDetail.name + ", " + this.wineDetail.type : this.$t("vueRoutes.defaultKeywords")},
                // Twitter Card
              // {name: 'twitter:card', content: "summary"},
              // {name: 'twitter:title', content: this.wineDetail ? `${this.wineDetail.name} - Quinta Do Barbusano` : "Quinta Do Barbusano"},
              // {name: 'twitter:description', content: this.wineDetail && this.wineDetail.description ? UTILS_HELPER.htmlToText(this.wineDetail.description) : this.$t("intro")},
              // //Facebook OpenGraph
              // {property: 'og:title', content: this.wineDetail ? `${this.wineDetail.property} - Quinta Do Barbusano` : "Quinta Do Barbusano"},
              // {property: 'og:site_name', content: 'Quinta Do Barbusano'},
              // {property: 'og:type', content: 'website'},
              // {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_FRONT, "/default.jpg"]) },
              // {property: 'og:description', content: this.wineDetail && this.wineDetail.description ? UTILS_HELPER.htmlToText(this.wineDetail.description) : this.$t("intro")},
          ]
      } 
  
      if(this.$route.params.wineSlug)
      {
          result.link = 
          [
              {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$route.params.wineSlug])}`},
              {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.$route.params.wineSlug])}`},
          ];
      }
      return result;
    }, 
    components: { Wines, LogoLoading },
    computed: {
      ...mapState({ 
        wineDetailOutput: state => state.wines.detailOutput,
      }),
      technicalFilePath: function()
      {
        return this.wineDetail.datasheet ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.wineDetail.datasheet]) : null;
      },
      nutritionalFilePath: function()
      {
        return this.wineDetail.nutritionalSheet ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.wineDetail.nutritionalSheet]) : null;
      },
      wineDetail(){
        return this.wineDetailOutput && this.wineDetailOutput.data ? this.wineDetailOutput.data : null;
      },
      type(){
        console.log(this.wineDetail);
        return this.wineDetail && this.wineDetail.types ? this.wineDetail.types[0] : null;
      },
      titleType(){
        var title;
  
        if(this.type == "White"){
          title = this.$t("type.white");
        }
        else if(this.type == "Red"){
          title = this.$t("type.red");
        }
        else{
          title = this.$t("type.reserve");
        }
  
        return title;
      },
      // featuredImage(){
      //   return this.wineDetail && this.wineDetail.photoPath ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.wineDetail.photoPath]) : null;
      // },
      processedName(){
        var result = this.wineDetail ? this.wineDetail.name : '';
        result = result.replace(/(.*?\s.*?\s)/g, '$1'+'</br>');
        return result;
      }
    },
    methods: {
      ...mapActions('wines', ['getDetails']),
      initializePage() {
        if (!this.$route.params.wineSlug) {
          router.push({ name: "Vinhos" });
        }
        else{
          if (this.$route.params.wine || !this.$route.params.wine ) {
            this.getDetails(this.$route.params.wineSlug);
          }
        }
      },
      joinPaths(relativePath){
        return API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, relativePath]); 
      }
    },
    mounted() {
      this.initializePage();
    },
    watch:
    {
      $route(to) {
        if(to.name == "WineDetail")
        {
          this.$router.go(0);
        }
      },
      wineDetailOutput: function(val)
      {
        if(val == false)
        {
          router.push("/vinhos");
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .wine-detail {
    padding-top: 130px;
  }
  .w-65{
    width: 65%;
  }
  .img-hover{
    max-height: 675px;
    object-fit: contain;
    transition: transform .2s;
  }
  .img-hover:hover {
    transform: scale(1.2);
  }
  .category {
    margin-top: 74px;
  }
  .wine-detail .name {
    font-size: 52px;
    line-height: 75px;
    margin-bottom: 40px;
    color: var(--primary-color);
    text-align: left;
    font-weight: bold;
  }
  .wine-detail .setor {
    margin-bottom: 44px;
    max-width: 550px;
  }
  .setor-title,
  .setor-year {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 10px;
    color: var(--secondary-color);
    text-transform: uppercase;
    text-align: left;
    font-weight: bold;
  
  }
  .wine-text-desc {
    font-size: 35px;
    line-height: 40px;
    color: var(--secondary-color);
    text-align: left;
    overflow-wrap: break-word;
  }
  .wine-text {
    font-size: 16px;
    line-height: 24px;
    color: var(--secondary-color);
    text-align: left;
  }
  
  .data-item img {
    height: 50px;
  }
  
  /* prizes-data */
  .prizes-data {
    position: relative!important;
    padding-top: 64px;
    /* padding-bottom: 16px; */
  }
  .prizes-data .wine-text {
      line-height: 22px;
  }
  .prizes-data .prizes{
    background-color: var(--primary-color);
    height: auto;
    padding-top: 55px;
    padding-bottom: 40px;
  }
  .prizes-data .specs{
    background-color: rgba(248,238,226,.24);
    height: auto;
    padding-top: 55px;
    padding-bottom: 40px;
  }
  .wine-text a{
      color: inherit;
  }
  .product .logo{
    width: auto;
    height: 90px;
    position: absolute;
    bottom: 15%;
    right: 0;
  }
  .container {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
  }
  @media screen and (min-width: 992px) {
    .col-xl-2{
      width: 19.667%;
    }
  }
  @media screen and (max-width: 991px) {
    .product {
      justify-content: center !important;
    }
    .prizes-data .data-item {
      margin-bottom: 32px;
    }
    .prizes-data {
      padding-top: 0;
      padding-bottom: 0;
      background: none;
    }
    .prizes-data .setor-title::before {
      content: "";
      position: absolute;
      top: 0;
      left: 1;
      right: 0;
      height: 14%;
      width: 100%;
      z-index: -1;
    }
    .prizes-data .data-info {
      padding-bottom: 22px;
    }
    .prizes-data .data-info::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
    }
    .img-hover:hover {
      transform: none;
    }
  }
  @media screen and (max-width: 768px) {
    .product .logo{
      position: unset;
      margin: 30px auto;
    }
  }
  @media screen and (max-width: 576px) {
    .wine-text-desc {
      font-size: 25px;
    }
  }
  </style>
  